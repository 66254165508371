<template>
  <v-card-text class="text-center pb-12">
    <h2 class="text-h6 black--text mt-n3 d-flex flex-column">
      영수증 {{ uploadFileLength }}장이 <br>
      성공적으로 제출되었습니다!
      <span class="mt-2">
        <v-icon
          color="green"
          size="128px"
        >mdi-check-circle-outline</v-icon>
      </span>
      감사합니다.
    </h2>

    <template v-if="gcCommon_refund.agency_survey_url">
      <v-btn
        :href="gcCommon_refund.agency_survey_url"
        target="_blank"
        class="mt-12"
        color="blue"
        depressed
        x-large
        block
        dark
      >설문조사</v-btn>
    </template>
  </v-card-text>
</template>

<script>
export default {
  name: "SubmitDone",
  computed: {
    uploadFileLength: vm => {
      const oldCount = vm.gcCommon_refundUser.refund_image_receipt?.length || 0
      const uploadCount = vm.gcSubmit_formData.files_upload?.length || 0

      return oldCount + uploadCount
    },
  },
}
</script>
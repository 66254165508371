<template>
  <v-card-text class="py-0">
    <v-form
      ref="form"
      v-model="$store.state.gsSubmit_form.valid"
      :disabled="gcSubmit_submitting"
    >
      <!--  인풋: 참가자 소속 부서 (2024-03-08 부로 사용안한다고 함 ^^)  -->
      <!--<v-text-field-->
      <!--  v-model="$store.state.gsSubmit_formData.refund_user_part"-->
      <!--  :class="privateMode ? 'mb-5' : ''"-->
      <!--  :clearable="!privateMode"-->
      <!--  :color="privateMode ? 'black' : ''"-->
      <!--  :hide-details="privateMode"-->
      <!--  :readonly="privateMode"-->
      <!--  :rules="[required]"-->
      <!--  dense-->
      <!--  label="진료과"-->
      <!--  outlined-->
      <!--/>-->
      <!--  인풋: 참가자 이름  -->
      <v-text-field
        v-model="$store.state.gsSubmit_formData.refund_user_name"
        :class="privateMode ? 'mb-5 grey lighten-4' : ''"
        :clearable="!privateMode"
        :color="privateMode ? 'black' : ''"
        :hide-details="privateMode"
        :readonly="privateMode"
        :rules="[required]"
        dense
        label="성명"
        outlined
      />
      <!--  인풋: 참가자 연락처  -->
      <v-text-field
        v-model="$store.state.gsSubmit_formData.refund_user_phone"
        :class="privateMode ? 'mb-5 grey lighten-4' : ''"
        :clearable="!privateMode"
        :color="privateMode ? 'black' : ''"
        :hide-details="privateMode"
        :readonly="privateMode"
        :rules="[required]"
        dense
        label="연락처"
        outlined
      />

      <!--  인풋: 참가자 소속 병원명  -->
      <v-text-field
        v-model="$store.state.gsSubmit_formData.refund_user_company"
        :rules="[requiredCustom]"
        clearable
        dense
        label="병원명"
        outlined
      />

      <!--  인풋: 참가자 병원 상세 주소  -->
      <v-text-field
        v-if="$store.state.gsCommon_refund.refund_starting_address_input_type"
        v-model="$store.state.gsSubmit_formData.refund_starting_address"
        :rules="[$store.state.gsCommon_refund.refund_starting_address_input_type === 2 ? requiredCustom : () => true]"
        clearable
        dense
        label="병원 상세 주소"
        outlined
      />

      <!--  인풋: 참가자 병원 상세 주소 타입  -->
      <v-select
        v-if="$store.state.gsCommon_refund.refund_starting_address_input_type && gcCommon_refund.refund_starting_address_type_use === 1"
        v-model="$store.state.gsSubmit_formData.refund_starting_address_type"
        :items="[{text:'병원',value:0},{text:'자택',value:1},]"
        dense
        label="출발지 주소 타입"
        outlined/>

      <!--  인풋: 커스텀 필드  -->
      <v-text-field
        v-for="(field, i) in $store.state.gsSubmit_formData.refund_user_custom_field"
        :key="`refund_user_custom_field-${ i }`"
        v-model="field.value"
        :label="field.label"
        :rules="[requiredCustom]"
        clearable
        dense
        outlined
      />

      <!--  그룹: 환급 버튼  -->
      <div class="d-flex mt-2 mb-7">
        <v-btn
          :color="gcSubmit_refundSubmitState === 1 ? 'blue' : ''"
          :dark="gcSubmit_refundSubmitState === 1 && !gcSubmit_submitting"
          :disabled="gcSubmit_submitting"
          class="font-weight-bold flex-grow-1 mr-2 px-0"
          depressed
          x-large
          @click="gcSubmit_refundSubmitState = 1"
        >
          <v-icon v-if="gcSubmit_refundSubmitState === 1">mdi-check-bold</v-icon>
          {{ `${ gcSubmit_refundSubmitState !== 2 ? '환급' : '' } 신청` }}
        </v-btn>
        <v-btn
          :color="gcSubmit_refundSubmitState === 2 ? 'blue' : ''"
          :dark="gcSubmit_refundSubmitState === 2 && !gcSubmit_submitting"
          :disabled="gcSubmit_submitting"
          class="font-weight-bold flex-grow-1 ml-2 px-0"
          depressed
          x-large
          @click="gcSubmit_refundSubmitState = 2"
        >
          <v-icon v-if="gcSubmit_refundSubmitState === 2">mdi-check-bold</v-icon>
          {{ `${ gcSubmit_refundSubmitState !== 1 ? '환급' : '' } 미신청` }}
        </v-btn>
      </div>

      <!--  그룹: 참가자 환급 시 추가 정보  -->
      <template v-if="gcSubmit_refundSubmitState === 1">
        <!--  인풋: 은행명  -->
        <v-select
          v-model="$store.state.gsSubmit_formData.refund_bank_code"
          :items="bankList"
          :rules="[required, val=>!!val||'은행을 선택해주세요']"
          attach="#select-bank"
          clearable
          dense
          label="은행명"
          outlined
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item
              class="pa-1 c-select-options"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-content>
                <v-icon
                  v-if="item.value === 'etc'"
                  size="48"
                >mdi-pencil-box-outline
                </v-icon>
                <v-img
                  v-else
                  :src="item.src"
                  aspect-ratio="1"
                  class="ma-auto"
                  contain
                  style="flex: initial !important;"
                  width="48px"
                />
                <span class="pt-1 text-center word-break-keep-all">
                  {{ item.text }}
                </span>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <div
          id="select-bank"
          class="position-relative mt-n6 mb-6"
        />
        <!--  인풋: 은행명 직접 입력  -->
        <v-text-field
          v-if="$store.state.gsSubmit_formData.refund_bank_code === 'etc'"
          v-model="$store.state.gsSubmit_formData.refund_bank_name"
          :rules="[required]"
          clearable
          dense
          label="은행명 직접입력"
          outlined
          type="text"
        />
        <!--  인풋: 계좌번호  -->
        <v-text-field
          v-model="$store.state.gsSubmit_formData.refund_account_number"
          :rules="[required]"
          clearable
          dense
          label="계좌번호(본인명의)"
          outlined
          type="tel"
          @input="onInputForOnlyNumber($event, 'account_number')"
        />
        <!--  인풋: 예금주  -->
        <v-text-field
          v-model="$store.state.gsSubmit_formData.refund_account_holder"
          :rules="[required]"
          clearable
          dense
          label="예금주"
          outlined
          type="text"
        />
      </template>

      <!--  그룹: 이미지 업로드  -->
      <submit-card-form-upload v-if="gcSubmit_refundSubmitState === 1"/>

      <!--  개인정보 동의  -->
      <template v-if="gcSubmit_refundSubmitState === 1 && gcCommon_refund.refund_terms_enable">
        <v-divider class="mt-2"/>

        <v-checkbox
          v-model="checkbox"
          :disabled="gcSubmit_submitting"
          :label="gcCommon_refund.refund_terms_label"
          :rules="[checked]"
          class="text-indent-5 word-break-keep-all mt-6"
        >
          <template v-slot:append>
            <v-btn
              :disabled="gcSubmit_submitting"
              depressed
              outlined
              small
              @click="onClickTerm"
            >더보기
            </v-btn>
          </template>
        </v-checkbox>
      </template>

      <!--  서명란  -->
      <v-sheet
        v-show="gcSubmit_refundSubmitState === 1"
        class="c-sign-wrap"
      >
        <div
          aria-hidden="true"
          class="c-sign-placeholder text-h1 grey--text text--lighten-3"
        >
          서명
        </div>

        <canvas
          id="canvas"
          height="180"
          tabindex="0"
          width="320"
          @mousedown="drawStart"
          @mousemove="drawProgressing"
          @mouseout="drawEnd"
          @mouseup="drawEnd"
          @touchend="drawEnd"
          @touchmove="drawProgressing"
          @touchstart="drawStart"
        />

        <div
          v-if="$store.state.gsSubmit_form.invalidSignText"
          class="c-sign-error-text"
        >
          <span class="px-1 white">{{ $store.state.gsSubmit_form.invalidSignText }}</span>
        </div>

        <v-btn
          v-if="$store.state.gsSubmit_form.signLength"
          :disabled="gcSubmit_submitting"
          absolute
          class="c-btn-retry px-2"
          depressed
          outlined
          small
          @click="onClickSignRetry"
        >다시하기
        </v-btn>
      </v-sheet>

    </v-form>

  </v-card-text>
</template>

<script>
import env from '../env'

import SubmitCardFormUpload from './SubmitCardFormUpload'

export default {
  name: "SubmitCardForm",

  mounted() {
    this.$store.state.gsSubmit_form.ref = this.$refs.form
  },

  updated() {
    // 켄버스 서명 구현
    //  - 환급 신청 여부에 따라 화면에 노출여부가 결정되는데
    //   미노출일 경우 clientWidth를 불러오지 못하므로
    //   mount 가 아닌 update 생명주기에서 컨트롤 한다

    // 비율계산은 화면 가로세로 변경이 있을 수 있으므로 매번 한다
    const cvs = document.getElementById('canvas')
    this.canvas.cvs = cvs
    this.canvas.ratio = cvs.clientWidth / cvs.width

    if (this.canvas.ctx === null) {
      this.canvas.ctx = cvs.getContext('2d')
    }
  },

  components: {
    SubmitCardFormUpload,
  },

  data: () => ({
    checkbox: false,

    // 켄버스 관련 객체
    canvas: {
      offsetTop: 0,
      offsetLeft: 0,
      ratio: 0,
      cvs: null,
      ctx: null,
      drawing: false,
    },
  }),

  computed: {
    // 기존 정보 업데이트 모드
    privateMode() {
      return this.$route.name === 'SubmitPrivate'
    },
    required() {
      return value => !!value || '내용을 입력해주세요.'
    },
    requiredCustom() {
      return value => !!value || '내용을 입력해주세요.'
    },
    checked() {
      return value => !!value || '동의가 필요합니다.'
    },
    bankList() {
      return env.BANK_LIST
    },
  },

  methods: {
    // 오직 숫자만 입력되도록 필터링
    onInputForOnlyNumber(val, key) {
      // 숫자 이외의 문자가 있을 경우
      if (val.match(/[^0-9]/)) {
        const formData = this.$store.state.gsSubmit_formData

        // 숫자 이외의 문자를 삭제 (비동기 처리)
        setTimeout(() => {
          formData[key] = val.replace(/[^0-9]/g, '')
        })
      }
    },

    // 약관 더보기 버튼 클릭시
    onClickTerm() {
      this.gmCommon_alertHtmlOpen(this.gcCommon_refund.refund_terms_html)
    },

    // 사인 다시하기
    onClickSignRetry() {
      const cvs = this.canvas.cvs
      const ctx = this.canvas.ctx

      ctx.clearRect(0, 0, cvs.width, cvs.height)
      this.$store.state.gsSubmit_form.signLength = 0
      this.$store.state.gsSubmit_formData.participant_sign = ''
    },

    // 켄버스 그리기 시작
    drawStart(event) {
      const cardEl = document.querySelector('.container>.v-card')
      this.canvas.offsetTop = this.canvas.cvs.parentElement.offsetTop + cardEl.offsetTop - window.scrollY
      this.canvas.offsetLeft = this.canvas.cvs.parentElement.offsetLeft + cardEl.offsetLeft - window.scrollX

      const x = this.posCalc((event.clientX || event.changedTouches?.[0]?.clientX) - this.canvas.offsetLeft)
      const y = this.posCalc((event.clientY || event.changedTouches?.[0]?.clientY) - this.canvas.offsetTop)

      const ctx = this.canvas.ctx

      this.canvas.drawing = true

      ctx.beginPath()
      ctx.moveTo(x, y)
      ctx.lineWidth = 2
      ctx.fillStyle = '#000000'
      ctx.strokeStyle = '#000000'
      ctx.shadowColor = null
      ctx.shadowBlur = null
    },

    // 켄버스 그리는 중
    drawProgressing(event) {
      event.preventDefault()
      if (!this.canvas.drawing) {
        return
      }

      const x = this.posCalc((event.clientX || event.changedTouches?.[0]?.clientX) - this.canvas.offsetLeft)
      const y = this.posCalc((event.clientY || event.changedTouches?.[0]?.clientY) - this.canvas.offsetTop)

      const ctx = this.canvas.ctx

      ctx.lineTo(x, y)
      ctx.stroke()

      this.$store.state.gsSubmit_form.signLength++
    },

    // 캔버스 그리기 끝
    drawEnd() {
      if (!this.canvas.drawing) {
        return
      }
      const ctx = this.canvas.ctx

      ctx.shadowColor = '#000000'
      ctx.shadowBlur = 2

      ctx.stroke()

      this.canvas.drawing = false
      this.$store.state.gsSubmit_formData.participant_sign = this.canvas.cvs.toDataURL('image/png')
      this.gsSubmit_signValid()
    },

    // 비율 계산 된 위치 값
    posCalc(value) {
      return value / this.canvas.ratio
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

.c-sign-wrap
  position: relative
  padding-top: 56.25%

  #canvas
    position: absolute
    top: 0
    left: 0
    width: 100%
    touch-action: none
    outline: 1px solid currentColor

  .c-sign-placeholder
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    letter-spacing: 1rem !important
    word-break: keep-all

  .c-sign-error-text
    position: absolute
    bottom: 0
    left: 0
    right: 0
    text-indent: .5rem
    color: map-get($red, 'accent-2')

  .c-btn-retry
    top: 4px
    right: 4px
    background-color: #ffffff
</style>
